var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',[_c('CToaster',{attrs:{"autohide":3000}},[_vm._l((_vm.infoList),function(info){return [_c('CToast',{key:info.message,attrs:{"show":true,"header":info.header,"color":info.color}},[_vm._v(" "+_vm._s(info.message)+". ")])]})],2)],1),_c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v(" Tenant ")]),_vm._v(" List ")]),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.computedItems,"fields":_vm.fields,"column-filter":"","items-per-page-select":"","items-per-page":10,"hover":"","sorter":"","pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"show_image",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CImg',{attrs:{"thumbnail":"","src":_vm.getImage(item),"height":"70","width":"70"}})],1)]}},{key:"show_details",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[_vm._v(" "+_vm._s(Boolean(item._toggled) ? "Hide" : "Show")+" ")])],1)]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"info"},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v(" Edit ")]),_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"danger"},on:{"click":function($event){return _vm.showDeleteConfirmation(item)}}},[_vm._v(" Delete ")])],1)],1)]}}])})],1),_c('CCardFooter',[_c('CButton',{attrs:{"type":"submit","size":"sm","color":"primary"},on:{"click":_vm.addNew}},[_c('CIcon',{attrs:{"name":"cil-check-circle"}}),_vm._v(" Add New")],1)],1)],1),_c('CModal',{attrs:{"title":"Confirm Delete","color":"warning","show":_vm.warningModal},on:{"update:show":[function($event){_vm.warningModal=$event},_vm.onDeleteConfirmation]}},[_vm._v(" Are you sure you want to delete this "+_vm._s(_vm.itemToDelete.code)+" ? ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }